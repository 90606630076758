// //
// //
// // self.addEventListener('push', function(event) {
// //     console.log({text: "push event on ngsw-worker", e: event});
// //     var data = event.data.json(),
// //         username = data.messageUserName || "",
// //         message = data.messageText || "",
// //         title = 'New message ' + (username?('from '+username):''),
// //         location = data.location || '',
// //         icon = data.iconPath || 'logo_freshchat.png',
// //         body = message || 'You received a notification';
// //
// //     event.waitUntil(
// //         self.registration.showNotification(title, {
// //             body: body,
// //             icon: icon,
// //             data: location
// //         })
// //     );
// // });
// //
// //
// //
// // if ('serviceWorker' in navigator) {
// //     console.log("THIS IS A SERVICE WORKER");
// //     navigator.serviceWorker.register('../firebase-messaging-sw.js')
// //         .then(function(registration) {
// //             console.log('Registration successful, scope is:', registration.scope);
// //         }).catch(function(err) {
// //         console.log('Service worker registration failed, error:', err);
// //     });
// // }
//
// self.addEventListener('push', function(event) {
//
//     var data = event.data.json();
//     // username = data.messageUserName || "",
//     // message = data.messageText || "",
//     // title = 'New message ' + (username?('from '+username):''),
//     // location = data.location || '',
//     // icon = data.iconPath || 'logo_freshchat.png',
//     // body = message || 'You received a notification';
//
//     console.log({text: "push event on firebase-messaging-sw", e: event, d: data});
//
//
//     event.waitUntil(
//         self.registration.showNotification(data.notification.title, {
//             body: data.notification.body,
//             data: data,
//             requireInteraction: false
//             // actions: [
//             //     data
//             // ]
//             // icon:,
//             // data: location
//         })
//     );
// });
//
//
// self.addEventListener('notificationclick', function(event) {
//     // let url = 'https://';
//     // var data = event.data.json();
//     // console.log({text: "NOTIFICATION CLICK", e: event});
//     let url = event.notification.data.data.link;
//     // let url = "http://localhost:4200/xat";
//     // console.log({text: "CLIENTS", clients: self.clients});
//     event.notification.close(); // Android needs explicit close.
//
//     console.log("Clicked push...");
//
//     const proms = [];
//
//     if (data.data.event === "type0bo") {
//         // fetch(data.data.internalLink).then(response => {})
//         proms.push(fetch(data.data.internalLink));
//         if (data.data.externalLink) {
//             proms.push(clients.openWindow(data.data.externalLink));
//             // clients.openWindow(data.data.externalLink).then(r => {});
//         }
//     }
//
//     proms.push(
//         self.clients.matchAll({type: 'window', includeUncontrolled: true}).then( windowClients => {
//             console.log("Checking windows...");
//             // Check if there is already a window/tab open with the target URL
//             for (var i = 0; i < windowClients.length; i++) {
//                 var client = windowClients[i];
//                 // If so, just focus it.
//                 // console.log({text: "TABS ", clients: client});
//                 if (client.url === url && 'focus' in client) {
//                     return client.focus();
//                 }
//             }
//             // If not, then open the target URL in a new window/tab.
//             if (clients.openWindow) {
//                 return clients.openWindow(url);
//             }
//         })
//     )
//
//     event.waitUntil(
//         Promise.all(proms).then(res => {
//             console.log({text: "results", res: res})
//         })
//     );
// });
